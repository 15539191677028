import * as React from "react";
import styled, { keyframes } from "styled-components";

const backgroundShining = keyframes`
  0% {
    background-position: 100% 50%;
  }
  
  100% {
    background-position: 0 50%;
  }
`;

const Line = styled.div<{ width: number }>`
  height: 24px;
  width: 100%;
  min-width: ${({ width }) => width}px;
  background: linear-gradient(89deg, #f2f2ff 25%, #dedfff 37%, #f2f2ff 70%);
  background-size: 400% 100%;
  animation: ${backgroundShining} 1.4s ease infinite;
`;

const Circle = styled.div`
  background: linear-gradient(89deg, #f2f2ff 25%, #dedfff 37%, #f2f2ff 70%);
  background-size: 400% 100%;
  animation: ${backgroundShining} 1.4s ease infinite;
  display: inline-block;
  min-width: 32px;
  width: 32px;
  min-height: 32px;
  height: 32px;
  border-radius: 20px;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  opacity: 0.6;
`;

export const LineContainer = styled.div<{ hasAvatar: boolean }>`
  flex: 1;
  ${({ hasAvatar }) =>
    hasAvatar &&
    `
    margin-top: 4px;
    margin-right: 10px;
  `}

  > *:not(:first-child) {
    margin-top: 10px;
  }
`;

interface IProps {
  /**
   * Should we display a loading circle representing an avatar ?
   * @default false
   */
  avatar?: boolean;
  /**
   * How many lines should we apply ?
   * @default 1
   */
  lines?: number;
  /**
   * Line width (in px)
   * @default 88
   */
  width?: number;
}

/**
 * Placeholder component to wait for data.
 */
export const Skeleton = ({ avatar, lines = 1, width = 88 }: IProps) => (
  <Container>
    {lines > 0 && (
      <LineContainer hasAvatar={!!avatar}>
        {Array.from(Array(lines)).map((_, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <Line width={width} key={index} />
        ))}
      </LineContainer>
    )}
    {avatar && <Circle />}
  </Container>
);
