function enumToArray(enumeration: any, ofString = true): any[] {
  return Object.keys(enumeration)
    .map((key: string) => enumeration[key])
    .filter(value =>
      ofString ? typeof value === "string" : typeof value === "number"
    );
}

const PREFIX = "ZZ_PERSIST_";

export enum PersistKey {
  READY = "READY",
  SIGNERS = "SIGNERS",
  SIGNERS_COUNT = "SIGNERS_COUNT",
  SHARERS = "SHARERS",
  SHARERS_COUNT = "SHARERS_COUNT",
}

export type LocalValue = object | string | number | boolean | undefined;

export class PersistValue<V extends LocalValue> {
  public static clear() {
    // Clear all local data
    enumToArray(PersistKey).forEach(key => {
      localStorage.removeItem(PersistValue.getLocalStorageKey(key));
    });

    // Notify instances
    PersistValue.instances.forEach(pv => {
      if (pv.onClear) {
        pv.onClear();
      }
    });
  }

  public static getLocalStorageKey(key: PersistKey) {
    return `${PREFIX}${key}`;
  }

  private static instances: Array<PersistValue<LocalValue>> = [];

  public onClear?: () => void;

  private readonly key: PersistKey;

  constructor(key: PersistKey) {
    this.key = key;
    PersistValue.instances.push(this);
  }

  public get(): V | undefined {
    if (!global.window) {
      return undefined;
    }

    const item = global.localStorage.getItem(this.getLocalStorageKey());
    if (item === null) {
      return undefined;
    }

    return JSON.parse(item);
  }

  public set(value: V) {
    global.localStorage.setItem(
      this.getLocalStorageKey(),
      JSON.stringify(value === undefined ? null : value)
    );
  }

  private getLocalStorageKey() {
    return PersistValue.getLocalStorageKey(this.key);
  }
}
