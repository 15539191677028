import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { LocalValue, PersistKey, PersistValue } from "./persist";

export function usePersistState<V extends LocalValue>(
  key: PersistKey,
  defaultValue: V
): [V, Dispatch<SetStateAction<V>>] {
  const persist = useMemo(() => new PersistValue<V>(key), [key]);
  const [state, setInternalState] = useState<V>(defaultValue);

  useEffect(() => {
    persist.onClear = () => setInternalState(defaultValue);

    return () => {
      delete persist.onClear;
    };
  }, [persist, defaultValue]);

  useEffect(() => {
    persist.set(state);
  }, [persist, state]);

  useEffect(() => {
    const initialState = persist.get();
    setInternalState(initialState === undefined ? defaultValue : initialState);
  }, [setInternalState]);

  return [state, setInternalState];
}
