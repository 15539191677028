import { createContext, useContext } from "react";

export interface Signer {
  id: string;
  firstname: string;
  datetime: string;
}

export interface ExternalData {
  ready: boolean;
  signers: Signer[];
  sharers: Signer[];
  share: {
    FACEBOOK: number;
    LINK: number;
    WHATSAPP: number;
    MESSENGER: number;
    TWITTER: number;
  };
  signersCount: number;
}

export const initialState: ExternalData = {
  ready: false,
  signers: [],
  sharers: [],
  share: {
    FACEBOOK: 0,
    LINK: 0,
    WHATSAPP: 0,
    MESSENGER: 0,
    TWITTER: 0,
  },
  signersCount: 0,
};

export const ExternalContext = createContext<ExternalData>(initialState);

export const useExternal = () => useContext(ExternalContext);
