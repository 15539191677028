import * as React from "react";
import styled from "styled-components";
// @ts-ignore
import chunk from "lodash.chunk";

const CountSpan = styled.span`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  font-size: 250px;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
  opacity: 0.2;
`;

interface Props {
  count: number;
}

export const Count = ({ count }: Props) => {
  const sCount: string[] = chunk(
    String(count)
      .split("")
      .reverse(),
    3
  )
    .map(digits => digits.reverse())
    .reverse();

  return (
    <CountSpan>
      {sCount.map(digits => (
        <React.Fragment key={digits}>
          {` `}
          {digits}
        </React.Fragment>
      ))}
    </CountSpan>
  );
};
