import * as React from "react";
import { useExternal } from "../technical/external-provider/content";
import { Gauge as GaugeComponent } from "../components/Gauge";

const GOAL_MAPPING = {
  100: 10,
  300: 50,
  500: 100,
  3000: 500,
  5000: 1000,
  10000: 2000,
  30000: 5000,
  1_000_000: 10000,
  1_000_000_000: 50_000,
};

export function getNextGoal(count: number) {
  const gp = Object.keys(GOAL_MAPPING)
    .map(gp => Number(gp))
    .sort((a, b) => a - b)
    .find(gp => gp > count);
  const goalStep: number = GOAL_MAPPING[gp];
  return goalStep * Math.floor(count / goalStep) + goalStep;
}

export const Gauge = () => {
  const { signersCount } = useExternal();

  return (
    <GaugeComponent count={signersCount} total={getNextGoal(signersCount)} />
  );
};
