import * as React from "react";
import styled from "styled-components";
import { PRIMARY } from "../constant/Colors";
// @ts-ignore
import chunk from "lodash.chunk";
import { TABLET } from "../constant/Breakpoints";
import { Count } from "./Count";

const Container = styled.header`
  position: relative;
  background: ${PRIMARY};
  color: white;
`;

const Content = styled.div`
  position: relative;
  padding: 96px 24px 56px 24px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 500px;
  flex-direction: column;

  @media (max-width: ${TABLET}px) {
    padding: 120px 24px 80px 24px;
  }
`;

const Title = styled.h1`
  font-size: 40px;
  font-weight: 800;
  text-transform: uppercase;
`;

const Description = styled.p`
  font-size: 26px;
  font-weight: 500;
`;

interface Props {
  count: number;
  title: string;
  img?: React.ReactNode;
  subtitle: string;
  cta?: React.ReactNode;
  maxWidth: number;
}

export const Header = ({
  count,
  title,
  img,
  subtitle,
  cta,
  maxWidth,
}: Props) => (
  <Container>
    {count >= 10_000 && <Count count={count} />}
    <Content style={{ maxWidth }}>
      <Title>{title}</Title>
      {img}
      <Description>{subtitle}</Description>
      {cta}
    </Content>
  </Container>
);
