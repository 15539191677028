import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBZL-Btx-DygMtln3Ana5ZlaLmXvP9RZtk",
  authDomain: "papillon-ou-zemmour.firebaseapp.com",
  projectId: "papillon-ou-zemmour",
  storageBucket: "papillon-ou-zemmour.appspot.com",
  messagingSenderId: "176647311387",
  appId: "1:176647311387:web:f0bcf768a30c499adaa8be",
};

// Initialize Firebase
initializeApp(firebaseConfig);

export const db = getFirestore();
