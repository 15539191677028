import * as React from "react";
import styled from "styled-components";
import { PRIMARY } from "../constant/Colors";

const Container = styled.div`
  position: relative;
  height: 30px;
  width: 100%;

  :before {
    position: absolute;
    content: " ";
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: ${PRIMARY};
    opacity: 0.1;
  }
`;

const Content = styled.div`
  height: 100%;
  background: ${PRIMARY};
`;

interface Props {
  count: number;
  total: number;
}

export const Gauge = ({ count, total }: Props) => (
  <Container>
    <Content style={{ width: `${Math.round((count / total) * 100)}%` }} />
  </Container>
);
