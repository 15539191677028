import * as React from "react";
import { PropsWithChildren, useCallback, useEffect } from "react";
import { ExternalContext, ExternalData, initialState, Signer } from "./content";
import {
  collection,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { db } from "../firestore";
import { usePersistState } from "../persist/usePersistState";
import { PersistKey } from "../persist/persist";

function reverseSortByDatetime(
  a: { datetime: string },
  b: { datetime: string }
) {
  return new Date(b.datetime).getTime() - new Date(a.datetime).getTime();
}

export const ExternalProvider = ({ children }: PropsWithChildren<{}>) => {
  const [ready, setReady] = usePersistState(
    PersistKey.READY,
    initialState.ready
  );
  const [signers, setSigners] = usePersistState(
    PersistKey.SIGNERS,
    initialState.signers
  );
  const addSigners = useCallback(
    (newSigners: Signer[]) => {
      setSigners(oldSigners =>
        [
          ...oldSigners,
          ...newSigners.filter(
            ({ id }) => !oldSigners.find(oldSigner => id === oldSigner.id)
          ),
        ]
          .sort(reverseSortByDatetime)
          .slice(0, 50)
      );
    },
    [setSigners]
  );
  const [sharers, setSharers] = usePersistState(
    PersistKey.SHARERS,
    initialState.sharers
  );
  const addSharers = useCallback(
    (newSharers: Signer[]) => {
      setSharers(oldSharers =>
        [
          ...oldSharers,
          ...newSharers.filter(
            ({ id }) => !oldSharers.find(oldSigner => id === oldSigner.id)
          ),
        ]
          .sort(reverseSortByDatetime)
          .slice(0, 10)
      );
    },
    [setSharers]
  );
  const [signersCount, setSignersCount] = usePersistState(
    PersistKey.SIGNERS_COUNT,
    initialState.signersCount
  );
  const [shareCount, setShareCount] = usePersistState(
    PersistKey.SHARERS_COUNT,
    initialState.share
  );

  useEffect(
    () =>
      onSnapshot(
        query(collection(db, "names"), orderBy("datetime", "desc"), limit(50)),
        snapshot => {
          addSigners(
            snapshot
              .docChanges()
              .filter(({ type }) => type === "added")
              .map(change => {
                const data = change.doc.data();

                return {
                  id: change.doc.id,
                  firstname: data.firstname,
                  datetime: data.datetime,
                };
              })
          );
        }
      ),
    [addSigners]
  );

  useEffect(
    () =>
      onSnapshot(
        query(
          collection(db, "sharers"),
          orderBy("datetime", "desc"),
          limit(10)
        ),
        snapshot => {
          addSharers(
            snapshot
              .docChanges()
              .filter(({ type }) => type === "added")
              .map(change => {
                const data = change.doc.data();

                return {
                  id: change.doc.id,
                  firstname: data.firstname,
                  datetime: data.datetime,
                };
              })
          );
        }
      ),
    [addSharers]
  );

  useEffect(
    () =>
      onSnapshot(doc(db, "counts/emails"), snapshot => {
        setSignersCount(snapshot.data().count);
        setReady(true);
      }),
    [setSignersCount, setReady]
  );

  useEffect(
    () =>
      onSnapshot(doc(db, "share/counts"), snapshot => {
        setShareCount(snapshot.data() as ExternalData["share"]);
      }),
    [setShareCount]
  );

  return (
    <ExternalContext.Provider
      value={{
        ready,
        signers,
        sharers,
        share: shareCount,
        signersCount,
      }}
    >
      {children}
    </ExternalContext.Provider>
  );
};
