import * as React from "react";
import { HTMLProps } from "react";
import styled from "styled-components";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { TABLET } from "../constant/Breakpoints";

const Container = styled.div`
  position: relative;
  :after {
    position: absolute;
    content: " ";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 100%
    );
  }

  .item-enter {
    opacity: 0;
    transform: scale(0.8);
  }
  .item-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: all 0.3s ease-in;
  }

  @media (min-width: ${TABLET}px) {
    font-size: 14px;
  }
`;

interface Props {
  lines: Array<{ line: React.ReactNode; key: string }>;
  className?: HTMLProps<HTMLDivElement>["className"];
}

export const OpacityLines = ({ lines, className }: Props) => (
  <Container className={className}>
    <TransitionGroup>
      {lines.map(({ line, key }) => (
        <CSSTransition key={key} timeout={300} classNames="item" exit={false}>
          {line}
        </CSSTransition>
      ))}
    </TransitionGroup>
  </Container>
);
